import React from "react"
// import { Link } from 'gatsby'

import Seo from "../../components/SEO"
import Layout from "../../components/trafficsigns/layout"
import AppStoreBadge from "../../components/AppStoreButton"

import logo from "../../images/trafficsigns/applogo.svg"
import favicon from "../../images/trafficsigns/favicon.png"
// 
import featureImage from "../../images/trafficsigns/header.png"

import imageRegs from "../../images/trafficsigns/regs.png"
import imageWarn from "../../images/trafficsigns/warn.png"
import imageInfo from "../../images/trafficsigns/guide.png"
import imageRecr from "../../images/trafficsigns/recr.png"

import imageVolume from "../../images/trafficsigns/volume2.png"
// import imagePrivacy from "../../images/trafficsigns/privacy.png"
import imageTechnology from "../../images/trafficsigns/technology.png"

// import seoFeatureImage from "../../images/bluescope/bluescope_seo.png"


function TrafficSignsIndexPage(props) {
    const seoImage = {
		src: featureImage,
		height: 256,
		width: 256,
		alt: "Traffic Signs Wizard (USA) logo",
    };
    
    // const appStoreUrl = "https://apps.apple.com/us/app/traffic-signs-wizard/id1543287536"
    const appStoreCampaignUrl = "https://apps.apple.com/app/apple-store/id1543287536?pt=121290356&ct=malaudhome&mt=8";
    const appStoreBadgeTitle = "Download Traffic Signs Wizard (USA) on the App Store";

    return (
    <Layout>
        <Seo
            siteTitle={'Traffic Signs Wizard (USA) for iOS'}
            seoTitle={"Traffic Signs Wizard (USA) for iOS"}
            path={props.location.pathname}
            metaImage={seoImage}
            icon={favicon}
            keywords={["USA Traffic Signs", "Traffic Signs", "Traffic Signs Images", "Traffic Signs Reference", "Traffic Signs Encyclopedia", "Traffic Signs Info", "Regulatory Traffic Signs", "Warning Traffic Signs", "Information Traffic Signs", "Recreational Traffic Signs", "Recreation Traffic Signs"]}
            description="Comprehensive Reference for US Traffic Signs"
            datePublished="03-21-2020"
            dateModified="11-01-2022"
            is_app={true}
            appName={"Traffic Signs Wizard"}
            appId={"1543287536"}
        />

        <div className={"page-header home"}>
            <br /><br />
            <img className={"applogo"} alt={"Logo"} src={logo}/>
            <br /><br />

            <h1 style={{marginBottom: 0, color: '#0398F4'}}>Traffic Signs Wizard</h1>
            <h2 style={{color: '#0398F4'}}>for iOS</h2>
            <h3 style={{marginBottom: 0, paddingBottom: 0}} className={"subtitle"}>
                Comprehensive Reference for Traffic Signs in USA
            </h3>
            <h4 style={{marginBottom: 0, paddingBottom: 0}} className={"subtitle"}>
                For kids, adults and everyone !
            </h4>

            <br />
            {/* <div className={"features"}>
                <p>The only comprehensive and dedicated reference for US Traffic Signs on the App Store.</p>
                <p>Be Aware of the Traffic Signs and Laws!</p>
                <p>Helps in DMV Test too!</p>
            </div> */}
                
            <br /><br />
            <img style ={{maxWidth: "80%"}} className={"featureImage"} alt={"Traffic Signs Wizard (USA) for iOS"} src={featureImage}/>
            <br /><br /><br />
            <p>Download on the App Store today!</p><br />
            <p>
                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrl} />
            </p>
        </div>

        <div className={"container"}>
            <div className={"features"}>
              <h1 className={"feature-title"}>Features</h1>
              
              

              <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Regulatory Traffic Signs</h2>
                                <p>
                                Regulatory Signs are meant to regulate traffic and are strictly enforced by law. Learn about the Regulatory Signs, and be aware of the Traffic laws in the USA.
                                </p>
                            </div>
                            
                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrl} />
                            </div>

                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Regulatory Traffic Signs"} src={imageRegs}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Warning Traffic Signs"} src={imageWarn}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Warning Traffic Signs</h2>
                                <p>Understanding the Warning Traffic signs can help avoid accidents & injury to yourself, and to others. These signs help make everyone safer on the road. Learn Warning Signs with high res images and detailed description. Impress family & friends and follow the law at the same time.</p>
                            </div>

                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrl} />
                            </div>

                        </div>
                    </div>
                </div>

                 <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Guide and Information Signs</h2>
                                <p>Guide Signs exist to help you find roadside services & facilities like food, gas, rest areas, etc. These signs are created to help make your travel better and comfortable. Learn about all the Guide Signs and be a smart traveler.</p>
                            </div>

                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrl} />
                            </div>

                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Guide Signs"} src={imageInfo}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Recreation Traffic Signs"} src={imageRecr}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Recreational Signs</h2>
                                <p>Recreational Signs are used for sporting activities, like biking, hiking, water sports, and winter sports, etc. Sports fans, nature lovers, and outdoor enthusiasts would love the collection. Share and show off your knowledge of these signs when you're out and about.</p>
                            </div>

                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrl} />
                            </div>

                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                    <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Built-in Audio Transcription</h2>
                                <p>Built-in audio transcription lets you listen to the Sign description, and additional guidance with your eyes off the screen. You can also adjust the speed of the speech as per your liking.</p>
                            </div>

                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrl} />
                            </div>

                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img style ={{maxWidth: "500%"}} alt={"Audio Notations"} src={imageVolume}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img style ={{maxWidth: "100%"}} alt={"Technology"} src={imageTechnology}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>And More...</h2>
                                <ul style ={{listStyle: "circle", textAlign: "left"}}>
                                    <li style={{margin: "10px 0"}}>Mark as read / unread for your reading progress.</li>
                                    <li style={{margin: "10px 0"}}>Save bookmarks to read later</li>
                                    <li style={{margin: "10px 0"}}>Complete Dark Mode Support</li>
                                </ul>
                            </div>

                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrl} />
                            </div>

                        </div>
                    </div>
                </div>

                {/* <div className={"feature__item"}>
                    <div className={"row"}>
                    <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Privacy</h2>
                                <p>
                                    Traffic Signs Wizard (USA) app does not collect, share or sell any personal or anonymous data with any third party or any advertising company. Read complete privacy policy <Link to="/usatrafficsigns/privacy">here</Link>.

                                </p>
                            </div>

                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrl} />
                            </div>

                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img style ={{maxWidth: "80%"}} alt={"Privacy"} src={imagePrivacy}/>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Accessibility</h2>
                                <p>Accessible first design focuses on making software usable for all. ActionTimer supports high contrast, reduced motion, inverse colors, and voice over support. 
                                <br /><br />ActionTimer also has full keyboard support for easy and quick operation.</p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Users"} src={thumbnailAcessibility}/>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
            
        </div>

        <div className={"call-to-action"}>
            {/* <div>
                <p>
                    <img style ={{maxWidth: "10%"}} alt={"Traffic Signs Wizard logo"} src={logo}/>
                </p>
            </div> */}

            <div className={"container"}>
                <div className={"call-to-action__content"}>
                    <img style ={{maxWidth: "20%"}} alt={"Traffic Signs Wizard logo"} src={logo}/>
                    <h2>Traffic Signs Wizard</h2>
                    <p>Download on the App Store today !</p>
                </div>

                <div className={"store-button"}>                
                    <br />
                    <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrl} />
                </div>

                
            </div>
        </div>
    </Layout>
    )
}

export default TrafficSignsIndexPage
